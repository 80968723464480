







































import { Component, Vue } from "vue-property-decorator";
import User from "@/api/User";
import UserRequired from "../components/UserRequired.vue";
import error from "@/api/errors";

Vue.component("UserRequired", UserRequired);

@Component
export default class Account extends Vue {
  private UM = User.um;
  private showError = error.bind(this, this);

  // Change Password
  oldPassword = "";
  newPassword = "";
  confirmPassword = "";
  passwordChanging = false;
  async changePassword(evt: Event): Promise<void> {
    evt.preventDefault(); // Needed to prevent the form from reloading the page
    if (!this.UM.loggedIn) return;
    this.passwordChanging = true;
    const showError = (message: string) =>
      this.showError(message, "Error changing password!");
    if (this.newPassword.length < 8) {
      showError("New password must be at least 8 characters long!");
      this.passwordChanging = false;
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      showError("New passwords don't match!");
      this.passwordChanging = false;
      return;
    }
    try {
      if (!(await this.UM.user.checkPassword(this.oldPassword))) {
        showError("Old Password incorrect!");
        this.passwordChanging = false;
        return;
      }
      await this.UM.user.changePassword(this.newPassword);
      this.showSuccess("Password changed successfully!");
    } catch (e) {
      showError(e.message);
    } finally {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.passwordChanging = false;
    }
  }

  // Change Username
  newUsername = "";
  confirmUsername = "";
  confPassword = "";
  usernameChanging = false;
  async changeUsername(evt: Event): Promise<void> {
    evt.preventDefault();
    const showError = (message: string) =>
      this.showError(message, "Error changing username");
    if (!this.UM.loggedIn) return;
    this.usernameChanging = true;
    if (this.newUsername.length < 3) {
      showError("New username must be at least 3 characters long!");
      this.usernameChanging = false;
      return;
    }
    if (this.newUsername == this.UM.user.username) {
      showError("New Username cannot be the same as your old one!");
      this.usernameChanging = false;
      return;
    }
    if (this.newUsername != this.confirmUsername) {
      showError("New usernames must match!");
      this.usernameChanging = false;
      return;
    }
    try {
      await this.UM.user.changeUsername(this.newUsername, this.confPassword);
      this.showSuccess("Username changed successfully!");
      this.newUsername = "";
      this.confirmUsername = "";
      this.confPassword = "";
      this.usernameChanging = false;
    } catch (e) {
      showError(e);
      this.confPassword = "";
      this.usernameChanging = false;
      return;
    }
  }

  // Generic Show Success Method
  showSuccess(message: string): void {
    this.$bvToast.toast(message, {
      title: "Success!",
      variant: "success",
      toaster: "b-toaster-top-center",
    });
  }

  deletionInProgress = false;
  // Delete Account
  convPassword = "";
  async onDelete(evt: Event): Promise<void> {
    evt.preventDefault();
    this.deletionInProgress = true;
    const showError = (message: string) =>
      this.showError(message, "Error Deleting Account!");
    if (this.UM.loggedIn) {
      try {
        if (!(await this.UM.user.checkPassword(this.convPassword))) {
          showError("Password incorrect!");
          this.deletionInProgress = false;
          return;
        }
        await this.UM.user.deleteUser();
        this.showSuccess("Account Deleted Successfully!");
      } catch (e) {
        this.showError(e.message, "Error Deleting Account!");
      } finally {
        this.convPassword = "";
        this.deletionInProgress = false;
      }
    }
  }
}
